import moment from "moment";
import { ServiceFactory } from "@/services";

const MassManagementService = ServiceFactory.get('massManagement')

export default {
  data: function () {
    return {
      isLoading: false,
      orderId: '',
      order: null
    }
  },

  computed: {
    isMassManagementPage() {
      return this.$route.name === 'routes.massManagement.edit'
    },

    isSampleManagementPage() {
      return this.$route.name === 'routes.massManagement.edit'
    }
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.orderId = this.$route.params.id || 0
      this.getOrderDetail()
    },

    formatDate(value) {
      if (!value) return ''
      return moment(value).format('YYYY-MM-DD') || ''
    },

    prepareData(data) {
      return {
        type: !isNaN(this.orderType) ? this.orderType : this.order.type ,
        categoryId: data.categoryId || this.order.categoryId,
        bodyId: data.bodyId || this.order.bodyId,
        brandId: data.brandId || this.order.brandId,
        factoryId: data.factoryId || this.order.factoryId,
        importerId: data.importerId || this.order.importerId,
        contentId: data.contentId || this.order.contentId,
        classifyId: data.classifyId || this.order.classifyId,
        code: data.code || this.order.code,
        sizeId: data.sizeId || this.order.sizeId,
        colorId: data.colorId || this.order.colorId,
        quantity: data.quantity || Number(data.quantity),
        unitPrice: data.unitPrice || Number(data.unitPrice),
        fee: data.fee || Number(data.fee),
        actualQuantity: Number(data.actualQuantity) || Number(this.order.actualQuantity),
        total: Number(data.total) || Number(this.order.total),
        formCode: data.formCode || this.order.formCode,
        paymentCode: data.paymentCode || this.da,
        orderDate: this.formatDate(data.orderDate || ''),
        specDate: this.formatDate(data.specDate || ''),
        deliveryDate: this.formatDate(data.deliveryDate || ''),
        file: data.file || this.order.file,
        note: data.note || this.order.note,
        internalNote: data.internalNote || this.order.internalNote
      }
    },

    handleOnFormSubmit(formData) {
      this.updateOrder(formData)
    },

    async getOrderDetail() {
      this.isLoading = true
      this.order = await MassManagementService.getById(this.orderId).catch(() => ({}))
      this.isLoading = false
    },

    async updateOrder(formData) {
      const data = this.prepareData(formData)

      this.isLoading = true
      const orderUpdated = await MassManagementService.update(this.orderId, data).catch(() => ({}))
      this.isLoading = false

      if (orderUpdated && orderUpdated.id) {
        this.$notify({
          message: 'オーダーの更新は完了しました。',
          type: 'success'
        })

        if (this.isMassManagementPage) return this.$router.push({ name: 'routes.massManagement' })
        return this.$router.push({ name: 'routes.sampleManagement' })
      }
    }
  }
}
