import moment from "moment";
import { ServiceFactory } from "@/services";

const MassManagementService = ServiceFactory.get('massManagement')

export default {
  data: function () {
    return {
      isLoading: false
    }
  },
  computed: {
    isMassManagementPage() {
      return this.$route.name === 'routes.massManagement.create'
    },

    isSampleManagementPage() {
      return this.$route.name === 'routes.massManagement.create'
    },
  },
  methods: {
    formatDate(value) {
      if (!value) return ''
      return moment(value).format('YYYY-MM-DD') || ''
    },

    prepareData(data) {
      return {
        type: this.orderType,
        categoryId: data.categoryId || 0,
        bodyId: data.bodyId || 0,
        brandId: data.brandId || 0,
        factoryId: data.factoryId || 0,
        importerId: data.importerId || 0,
        contentId: data.contentId || 0,
        classifyId: data.classifyId || 0,
        code: data.code || '',
        sizeId: data.sizeId  || 0,
        colorId: data.colorId || 0,
        quantity: data.quantity || 0,
        unitPrice: Number(data.unitPrice) || 0,
        fee: data.fee || 0,
        actualQuantity: Number(data.actualQuantity) || 0,
        total: data.total || 0,
        formCode: data.formCode || '',
        paymentCode: data.paymentCode || '',
        orderDate: this.formatDate(data.orderDate || ''),
        specDate: this.formatDate(data.specDate || ''),
        deliveryDate: this.formatDate(data.deliveryDate || ''),
        file: data.file || '',
        note: data.note || '',
        internalNote: data.internalNote || ''
      }
    },
    async handleOnFormSubmit(formData) {
      const data = this.prepareData(formData)

      this.isLoading = true
      const newMassOrder = await MassManagementService.create(data).catch(() => null)
      this.isLoading = false

      if (newMassOrder && newMassOrder.id) {
        this.$notify({
          message: '新規登録は完了しました。',
          type: 'success'
        })

        if (this.isMassManagementPage) return this.$router.push({ name: 'routes.massManagement' })
        return this.$router.push({ name: 'routes.sampleManagement' })
      }
    }
  }
}
