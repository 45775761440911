import _ from "lodash";
import VueCookies from "vue-cookies";
import moment from "moment";
import {FilterLimit} from "../constants";
import { eventBusService, ServiceFactory } from "@/services";
import { numberComma } from "@/utils/number.util";
import {
  TypeMasterData,
  MasterDataTypes,
  OrderType,
  FilterData,
  Permission,
  COOKIES
} from "@/constants";

// const day = new moment()
// const startDay = day.startOf('month').format('YYYY-MM-DD')
// const endDay = day.endOf('month').format('YYYY-MM-DD')

const MassManagementService = ServiceFactory.get('massManagement')
const MasterManagementService = ServiceFactory.get('master')

const DefaultFilterData = {
  type: OrderType.Mass,
  startDate: null,
  endDate: null,
  sort: '',
  order: '',
  page: FilterData.Page,
  limit: Number(FilterLimit.firstLimit),
  brandId: '',
  categoryId: '',
  bodyId: '',
  factoryId: '',
  importerId: '',
  contentId: '',
  classifyId: '',
  sizeId: '',
  colorId: '',
  status: '',
};

export default {
  data: function () {
    return {
      isLoading: false,
      isExporting: false,
      isShowDeleteDialog: false,
      isDeleting: false,
      orderDeleteId: null,
      masterDataTypeOptions: MasterDataTypes,
      companyOptions: [],
      filter: null,
      filterData: _.cloneDeep(DefaultFilterData),
      useRole: Number(VueCookies.get(COOKIES.role)),
      totalOrder: 0,
      totalPrice: 0,
      categoryOptions: [],
      bodyOptions: [],
      brandOptions: [],
      orderFactoryOptions: [],
      importerOptions: [],
      contentOptions:[],
      classOptions: [],
      sizeOptions: [],
      colorOptions: [],
      drawer: false
    }
  },

  computed: {
    isMassManagementPage() {
      return this.$route.name === 'routes.massManagement'
    },

    isSampleManagementPage() {
      return this.$route.name === 'routes.sampleManagement'
    },

    isAdmin() {
      return this.useRole === Permission.Admin
    },
    isCompany() {
      return this.useRole === Permission.User
    }
  },

  created() {
    eventBusService.$on(eventBusService.SHOW_DELETE_MODAL_SUBMIT, this.deleteOrder)
    this.init()
  },

  methods: {
    tableRowClassName({ row }) {
      if (!row.deliveryDate || row.deliveryDate === '_') return 'row-not-delivery'
      return 'row-delivered'
    },

    clickToSearchButton() {
      this.drawer = false
      this.filterData.startDate = this.filterData.startDate ? moment(String(this.filterData.startDate)).format('YYYY-MM-DD') : ''
      this.filterData.endDate =  this.filterData.endDate ? moment(String(this.filterData.endDate)).format('YYYY-MM-DD') : ''
      this.filter()
    },

    clickToResetButton() {
      this.drawer = false
      this.filterData = _.cloneDeep(DefaultFilterData)
      this.filterData.type = this.isMassManagementPage ? OrderType.Mass : OrderType.Sample
      this.filter()
    },

    clickToDownloadCSVButton() {
      this.exportCSV()
    },

    clickToDeleteButton(orderIndex) {
      const item = this.table.items.find((item, index) => index === orderIndex) || null
      if (!item || !item.id) return

      this.orderDeleteId = item.id
      this.isShowDeleteDialog = true

      eventBusService.$emit(eventBusService.SHOW_DELETE_MODAL, {
        data: this.orderDeleteId,
        title: 'オーダーを削除',
        message: 'このオーダーを削除してもよろしいでしょうか？',
        subMessage: '削除した後、元に戻すことができません。'
      })
    },

    onCurrentPageChange(page) {
      this.filterData.page = page
      this.filter()
    },

    onLimitChange(limit) {
      this.filterData.limit = limit
      this.filterData.page = 1
      this.filter()
    },

    downloadCSVFile(url) {
      if (!url) return

      let tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = url;
      tempLink.click();
      window.URL.revokeObjectURL(url);
    },

    sortChange(column) {
      this.filterData.sort = column.prop;
      this.filterData.order = column.order === 'ascending' ? 'asc' : 'desc';
      this.filter()
    },

    async init() {
      if (this.isMassManagementPage) this.filterData.type = OrderType.Mass
      if (this.isSampleManagementPage) this.filterData.type = OrderType.Sample

      this.filter = _.debounce(() => this.search(), FilterData.Debounce)
      this.filter()

      if (this.isAdmin) this.companyOptions = await this.getCompanyOptions()
    },

    async getCompanyOptions() {
      const options = await MasterManagementService.getItemsList({type: TypeMasterData.brand}).catch(() => [])
      return options
    },

    async exportCSV() {
      const queryParam = _.cloneDeep(this.filterData)
      delete queryParam.limit

      this.isExporting = true
      const { url } = await MassManagementService.export(this.filterData).catch(() => null)
      this.isExporting = false

      if (url) return this.downloadCSVFile(url)

      this.$notify({
        title: 'Error',
        message: 'Can not download CSV file',
        type: 'error'
      })
    },

    async search() {
      if (!this.isAdmin && !this.isCompany) return

      let searchNameMethod = ''
      if (this.isAdmin) searchNameMethod = 'search'
      if (this.isCompany) searchNameMethod = 'searchCompanyOrder'

      this.isLoading = true
      const res = await MassManagementService[searchNameMethod](this.filterData).catch(() => ({total: 0, items: []}))
      this.isLoading = false

      this.table.items = res.items || []
      this.table.total = res.total || 0
      this.totalOrder = res.sumQuantity || 0
      this.totalPrice = res.sumTotal || 0

      this.table.items.map(item => {
        item.deliveryDate = item.deliveryDate ? moment(item.deliveryDate).format('YYYY/MM/DD') : '_'
        item.orderDate = item.orderDate ? moment(item.orderDate).format('YYYY/MM/DD') : ''
        item.specDate = item.specDate ? moment(item.specDate).format('YYYY/MM/DD') : ''
        item.updatedAt = item.updatedAt ? moment(item.updatedAt).format('YYYY/MM/DD') : ''

        return item
      })
    },

    async deleteOrder() {
      if (!this.orderDeleteId) return

      this.isDeleting = true
      const error = await MassManagementService.delete(this.orderDeleteId).catch((error) => error)
      this.isDeleting = false

      if (error) return this.$notify({
        title: 'Error',
        message: error,
        type: 'error'
      })

      this.orderDeleteId = null
      this.isShowDeleteDialog = false
      this.filterData.page = 1

      this.$notify.success({
        message: 'オーダーの削除は完了しました。',
      })

      eventBusService.$emit(eventBusService.HIDE_DELETE_MODAL)

      return this.filter()
    },
    formatNumberComma(value) {
      return numberComma(value)
    },
  }
}
